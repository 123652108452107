import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ErrorMessage from '../../layouts/ErrorMessage'
import { memo } from 'react'
import { selectError, selectIsLoading, selectSignUpStep } from '../../../reducers/user'
import BlueManIcon from '../../../assets/images/bule-man-icon.svg'
import YellowManIcon from '../../../assets/images/yellow-man-icon.svg'
import { signUp, confirmSignUpEmail, resendConfirmationToken, createAccount, selectPlan } from './slice'
import { Plans } from '../../../utils/utils'
import {
  AccountTypeBenefitsList,
  AccountTypeBlueIconContainer,
  AccountTypeContainerLeft,
  AccountTypeContainerRight,
  AccountTypeHeader,
  AccountTypeNameContainer,
  AccountTypePrice,
  AccountTypesContainer,
  AccountTypeYellowIconContainer,
  AdditionalLinks,
  BlueIcon,
  BottomChatImage,
  BottomContainer,
  CenteredFormContainer,
  CheckBoxActive,
  CheckBoxDeactivated,
  ContentContainer,
  ExtraLink,
  LeftSideContainer,
  Logo,
  ModelImage,
  RightSideContainer,
  TopChatImage,
  TopContainer,
  TopContainerLink,
} from '../styles'
import ModelImagePng from '../../../assets/images/model.png'
import Chat1ImagePng from '../../../assets/images/chat1.png'
import Chat2ImagePng from '../../../assets/images/chat2.png'
import LogoImage from '../../../assets/images/logo.svg'
import { CallToActionButton, Clear, PageTitleBig, SecondaryActionButton } from '../../common/styles'
import { FieldContainer, FieldLabel, TextField } from '../../common/form-styles'

function SignUp() {
  const [plan, setPlan] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [tokenValue, setTokenValue] = useState('')
  const [name, setName] = useState('')
  const error = useSelector(selectError)
  const signUpStep = useSelector(selectSignUpStep)
  const inProgress = useSelector(selectIsLoading)

  const dispatch = useDispatch()

  const changeName = (event) => {
    setName(event.target.value)
  }

  const changePlan = (plan) => {
    setPlan(plan)
  }

  const changeEmail = (event) => {
    setEmail(event.target.value)
  }

  const changePassword = (event) => {
    setPassword(event.target.value)
  }

  const changeToken = (event) => {
    const token = event.target.value
    setTokenValue(event.target.value)
    if (token.length === 6) {
      dispatch(confirmSignUpEmail({ token }))
    }
  }

  const resendToken = (event) => {
    event.preventDefault()
    dispatch(resendConfirmationToken({ email }))
  }

  const createUserAccount = (event) => {
    event.preventDefault()
    dispatch(createAccount({ name, password }))
  }

  const selectUserPlan = (event) => {
    event.preventDefault()
    dispatch(selectPlan({ plan }))
  }

  const signUpUser = (event) => {
    event.preventDefault()
    dispatch(signUp({ email }))
  }

  return (
    <ContentContainer>
      <LeftSideContainer>
        <ModelImage src={ModelImagePng} alt={'Model'} />
        <TopChatImage src={Chat1ImagePng} alt={'Chat 1'} />
        <BottomChatImage src={Chat2ImagePng} alt={'Chat 2'} />
      </LeftSideContainer>
      <RightSideContainer>
        <TopContainer>
          <Logo src={LogoImage} alt="Chameo" />
          <TopContainerLink to="/sign-in">Sign in</TopContainerLink>
        </TopContainer>
        <BottomContainer>
          <PageTitleBig>Start using chameo now</PageTitleBig>
          <ErrorMessage error={error} />
          {signUpStep === 1 && (
            <CenteredFormContainer onSubmit={signUpUser}>
              <fieldset disabled={inProgress}>
                <FieldContainer>
                  <fieldset>
                    <FieldLabel>
                      Email
                      <TextField
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                        value={email}
                        onChange={changeEmail}
                      />
                    </FieldLabel>
                  </fieldset>
                </FieldContainer>

                <FieldContainer>
                  <CallToActionButton type="submit">Sign up</CallToActionButton>
                </FieldContainer>
              </fieldset>
            </CenteredFormContainer>
          )}

          {signUpStep === 2 && (
            <CenteredFormContainer>
              <fieldset disabled={inProgress}>
                <FieldContainer>
                  <fieldset>
                    <FieldLabel>
                      Code from the email
                      <TextField
                        type="text"
                        placeholder="Token"
                        autoComplete="token"
                        value={tokenValue}
                        onChange={changeToken}
                      />
                    </FieldLabel>
                  </fieldset>
                </FieldContainer>

                <FieldContainer>
                  <SecondaryActionButton type="button" onClick={resendToken}>
                    Resend token
                  </SecondaryActionButton>
                </FieldContainer>
              </fieldset>
            </CenteredFormContainer>
          )}

          {signUpStep === 3 && (
            <CenteredFormContainer onSubmit={createUserAccount}>
              <FieldContainer>
                <fieldset>
                  <FieldLabel>
                    Your full name
                    <TextField
                      type="text"
                      placeholder="Your name"
                      autoComplete="name"
                      value={name}
                      onChange={changeName}
                    />
                  </FieldLabel>
                </fieldset>
              </FieldContainer>

              <FieldContainer>
                <fieldset>
                  <FieldLabel>
                    Your new password
                    <TextField
                      type="password"
                      autoComplete="new-password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={changePassword}
                    />
                  </FieldLabel>
                </fieldset>
              </FieldContainer>

              <FieldContainer>
                <CallToActionButton type="submit">Create account</CallToActionButton>
              </FieldContainer>
            </CenteredFormContainer>
          )}

          {signUpStep === 4 && (
            <div>
              <AccountTypesContainer>
                <AccountTypeContainerLeft onClick={() => changePlan(Plans.CREATOR)}>
                  <AccountTypeHeader>
                    <AccountTypeBlueIconContainer>
                      <BlueIcon src={BlueManIcon} alt={'Creator icon'} />
                    </AccountTypeBlueIconContainer>
                    <AccountTypeNameContainer>
                      {plan === Plans.CREATOR ? <CheckBoxActive /> : <CheckBoxDeactivated />}
                      Creator
                    </AccountTypeNameContainer>
                  </AccountTypeHeader>
                  <AccountTypeBenefitsList>
                    <li>Unlimited collaboration</li>
                    <li>Asset management</li>
                    <li>Custom apperance</li>
                    <li>Custom colorways</li>
                    <li>Sharable link</li>
                  </AccountTypeBenefitsList>
                  <AccountTypePrice>9€</AccountTypePrice>
                </AccountTypeContainerLeft>

                <AccountTypeContainerRight onClick={() => changePlan(Plans.CLIENT)}>
                  <AccountTypeHeader>
                    <AccountTypeYellowIconContainer>
                      <BlueIcon src={YellowManIcon} alt={'Creator icon'} />
                    </AccountTypeYellowIconContainer>
                    <AccountTypeNameContainer>
                      {plan === Plans.CLIENT ? <CheckBoxActive /> : <CheckBoxDeactivated />}
                      Client
                    </AccountTypeNameContainer>
                  </AccountTypeHeader>
                  <AccountTypeBenefitsList>
                    <li>Unlimited collaboration</li>
                    <li>Asset management</li>
                    <li>3 apperance</li>
                    <li>3 colorways</li>
                    <li>Embedding link</li>
                  </AccountTypeBenefitsList>
                  <AccountTypePrice>199€</AccountTypePrice>
                </AccountTypeContainerRight>
                <Clear />
              </AccountTypesContainer>

              <CenteredFormContainer onSubmit={selectUserPlan}>
                <FieldContainer>
                  <CallToActionButton type="submit">Get started</CallToActionButton>
                </FieldContainer>
              </CenteredFormContainer>
            </div>
          )}
        </BottomContainer>
        <AdditionalLinks>
          <ExtraLink to={'#'}>Terms and conditions</ExtraLink>
          <ExtraLink to={'#'}>Privacy policy</ExtraLink>
        </AdditionalLinks>
      </RightSideContainer>
    </ContentContainer>
  )
}

export default memo(SignUp)
