import React, { memo, useEffect, useState } from 'react'
import ItemList from '../item-list/ItemList'
import { getRecentItems } from '../item-list/slice'
import { useDispatch } from 'react-redux'
import { itemsUnloaded } from '../../reducers/item-list'
import {
  ContentContainer,
  FiguresImageContainer,
  FileListContainer,
  UploadFilesButtonContainer,
  UploadFilesButtonMessage,
  UploadFilesContentContainer,
} from './styles'
import { BlockTitle, CallToActionButton } from '../common/styles'
import FiguresImage from '../../assets/images/figures.svg'

function Dashboard() {
  const dispatch = useDispatch()
  const [hasFiles, setHasFiles] = useState(false)

  useEffect(() => {
    const fetchItems = dispatch(getRecentItems())

    return () => {
      dispatch(itemsUnloaded())
      fetchItems.abort()
    }
  }, [])

  return (
    <ContentContainer>
      {hasFiles && (
        <FileListContainer>
          <BlockTitle>Recently viewed</BlockTitle>
          <ItemList />
        </FileListContainer>
      )}
      {!hasFiles && (
        <UploadFilesContentContainer>
          <FiguresImageContainer src={FiguresImage} alt={'Upload files'} />
          <UploadFilesButtonContainer>
            <CallToActionButton
              type="button"
              onClick={() => {
                setHasFiles(true)
              }}
            >
              Upload your first 3D file(s)
            </CallToActionButton>
            <UploadFilesButtonMessage>
              Supported file format:
              <br />
              .obj, .glb, .fbx,.usdz
            </UploadFilesButtonMessage>
          </UploadFilesButtonContainer>
        </UploadFilesContentContainer>
      )}
    </ContentContainer>
  )
}

export default memo(Dashboard)
