import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signIn } from './slice'
import ErrorMessage from '../../layouts/ErrorMessage'
import { selectError, selectIsLoading } from '../../../reducers/user'
import {
  AdditionalLinks,
  BottomChatImage,
  BottomContainer,
  CenteredFormContainer,
  ContentContainer,
  ExtraLink,
  LeftSideContainer,
  Logo,
  ModelImage,
  RightSideContainer,
  TopChatImage,
  TopContainer,
  TopContainerLink,
} from '../styles'
import LogoImage from '../../../assets/images/logo.svg'
import ModelImagePng from '../../../assets/images/model.png'
import Chat1ImagePng from '../../../assets/images/chat1.png'
import Chat2ImagePng from '../../../assets/images/chat2.png'
import { CallToActionButton, PageTitleBig } from '../../common/styles'
import { FieldContainer, FieldLabel, FormContainer, MainButton, TextField } from '../../common/form-styles'

function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const error = useSelector(selectError)
  const inProgress = useSelector(selectIsLoading)

  const dispatch = useDispatch()

  const changeEmail = (event) => {
    setEmail(event.target.value)
  }

  const changePassword = (event) => {
    setPassword(event.target.value)
  }

  const signInUser = (event) => {
    event.preventDefault()
    dispatch(signIn({ email, password }))
  }

  return (
    <ContentContainer>
      <LeftSideContainer>
        <ModelImage src={ModelImagePng} alt={'Model'} />
        <TopChatImage src={Chat1ImagePng} alt={'Chat 1'} />
        <BottomChatImage src={Chat2ImagePng} alt={'Chat 2'} />
      </LeftSideContainer>
      <RightSideContainer>
        <TopContainer>
          <Logo src={LogoImage} alt="Chameo" />
          <TopContainerLink to="/sign-up">Sign up</TopContainerLink>
        </TopContainer>
        <BottomContainer>
          <PageTitleBig>Sign In</PageTitleBig>
          <ErrorMessage error={error} />

          <CenteredFormContainer onSubmit={signInUser}>
            <fieldset disabled={inProgress}>
              <FieldContainer>
                <fieldset>
                  <FieldLabel>
                    Email
                    <TextField
                      type="email"
                      placeholder="Email"
                      autoComplete="email"
                      value={email}
                      onChange={changeEmail}
                    />
                  </FieldLabel>
                </fieldset>
              </FieldContainer>

              <FieldContainer>
                <fieldset className="form-group">
                  <FieldLabel>
                    Password
                    <TextField
                      type="password"
                      autoComplete="new-password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={changePassword}
                    />
                  </FieldLabel>
                </fieldset>
              </FieldContainer>

              <FieldContainer>
                <CallToActionButton type="submit">Sign in</CallToActionButton>
              </FieldContainer>
            </fieldset>
          </CenteredFormContainer>
        </BottomContainer>
        <AdditionalLinks>
          <ExtraLink to={'#'}>Terms and conditions</ExtraLink>
          <ExtraLink to={'#'}>Privacy policy</ExtraLink>
        </AdditionalLinks>
      </RightSideContainer>
    </ContentContainer>
  )
}

export default memo(SignIn)
