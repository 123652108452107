import styled from 'styled-components'

export const PageTitleBig = styled.h1`
  position: relative;
  font-weight: bold;
  font-size: 42px;
  text-align: center;
  width: 100%;
  padding: 32px 0;
`

export const CallToActionButton = styled.button`
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  text-align: center;
  background: #2352cc;
  border: none;
  color: #ffffff;
  font-weight: 500;
  font-family: 'Poppins';

  box-sizing: border-box;
  margin: 12px 0;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  font-size: 18px;
  transition: background-color 275ms ease;

  &:hover {
    cursor: pointer;
    background: #3e66cb;
  }
`

export const SecondaryActionButton = styled.button`
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  text-align: center;
  background: none;
  border: none;
  color: #000000;
  font-weight: 500;
  font-family: 'Poppins';

  box-sizing: border-box;
  margin: 12px 0;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  font-size: 18px;
  transition: color 275ms ease;

  &:hover {
    cursor: pointer;
    color: #999999;
  }
`

export const Clear = styled.div`
  clear: both;
  width: 100%;
  height: 0;
`

export const Icon = styled.img`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
`

export const BlockTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #3b3b3b;
`
