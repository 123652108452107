import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppAgent} from "../../api/appAgent";

export const initApp = createAsyncThunk(
    'common/app-loading',
    async (undefined, thunkApi) => {
        try {
            return await AppAgent.init();
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);