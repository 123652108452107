import axios from 'axios/index'

const API_ROOT = process.env.API_URL ?? 'http://core.chameo.local/api'

let apiToken = null

const agent = async (url, data = {}, method = 'GET') => {
  let headers = {}
  if (apiToken) {
    headers = {
      'AT-Access-Token': apiToken,
    }
  }
  const response = await axios({ method, url: `${API_ROOT}${url}`, data, headers })

  let result
  if (response.data.requestStatus === 'ok') {
    result = response.data.data
  } else {
    result = {
      error: {
        statusCode: response.status,
        internalCode: response.internalCode,
        message: response.message,
      },
    }

    throw result
  }

  return result
}

export const requests = {
  get: (url) => agent(url),
  post: (url, body) => agent(url, body, 'POST'),
}

export default {
  setApiToken: (_apiToken) => {
    apiToken = _apiToken
  },
}
