export const Status = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const Plans = {
  CLIENT: 'client',
  CREATOR: 'creator',
}