import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormContainer } from '../common/form-styles'

export const ContentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  background: #ffffff;
`

export const LeftSideContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  background: #f4b140;
  float: left;
  overflow: hidden;
`

export const RightSideContainer = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  float: right;
`

export const TopChatImage = styled.img`
  position: absolute;
  width: 302px;
  height: 160px;
  top: 15%;
  left: 40px;
`

export const BottomChatImage = styled.img`
  position: absolute;
  width: 302px;
  height: 160px;
  top: 50%;
  right: 60px;
`

export const ModelImage = styled.img`
  position: absolute;
  width: 426px;
  height: 660px;
  bottom: 0;
  left: calc(50% - 213px);
`

export const TopContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80px;
  line-height: 80px;
  padding: 0 60px;
  margin-top: 80px;
  box-sizing: border-box;
`

export const BottomContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 40px;
`

export const Logo = styled.img`
  position: relative;
  width: 159px;
  height: 50px;
  float: left;
  margin-top: 15px;
`

export const TopContainerLink = styled(Link)`
  position: relative;
  float: right;
`

export const CenteredFormContainer = styled(FormContainer)`
  width: 406px;
  margin: 0 auto;
`

export const AdditionalLinks = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 72px;
  line-height: 24px;
  text-align: right;
  padding: 24px 0;
  padding-right: 48px;
  box-sizing: border-box;
`

export const ExtraLink = styled(Link)`
  color: #9b9a9a;
  font-size: 18px;
  padding: 0 24px;
`

export const AccountTypesContainer = styled.div`
  position: relative;
  margin: 18px auto;
  width: 480px;
`

export const AccountTypeContainer = styled.div`
  position: relative;
  width: 204px;

  padding-bottom: 18px;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  -webkit-box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);

  transition: box-shadow 275ms ease, background 275ms ease;

  &:hover {
    cursor: pointer;

    background: #fafafa;

    -webkit-box-shadow: 0px 0px 8px 0px rgba(235, 235, 235, 1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(235, 235, 235, 1);
    box-shadow: 0px 0px 8px 0px rgba(235, 235, 235, 1);
  }
`

export const AccountTypeContainerLeft = styled(AccountTypeContainer)`
  float: left;
`

export const AccountTypeContainerRight = styled(AccountTypeContainer)`
  float: right;
`

export const AccountTypeHeader = styled.div`
  position: relative;
  width: 100%;
  height: 200px;

  padding-top: 18px;

  background: #ffffff;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  -webkit-box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);
`

export const AccountTypeBlueIconContainer = styled.div`
  position: relative;
  width: calc(100% - 36px);
  height: 144px;

  text-align: center;

  margin: 0 auto;

  background: #d3dcf5;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
`

export const AccountTypeYellowIconContainer = styled(AccountTypeBlueIconContainer)`
  background: #fbe0b3;
`

export const AccountTypeNameContainer = styled.div`
  position: relative;
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  padding-top: 12px;
`

export const CheckBoxActive = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;

  margin-top: -2px;
  margin-right: 8px;

  background: #2352cc;

  vertical-align: middle;

  display: inline-block;
`

export const CheckBoxDeactivated = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  display: inline-block;

  margin-top: -2px;
  margin-right: 8px;

  vertical-align: middle;

  background: #c4c4c4;

  transition: background 275ms ease;

  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;

  &:hover {
    cursor: pointer;
    background: #d3dcf5;
  }
`

export const AccountTypeBenefitsList = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 18px;

  li {
    position: relative;
    padding: 4px 0;
    font-size: 14px;
    box-sizing: border-box;
    text-align: center;
  }
`

export const AccountTypePrice = styled.div`
  position: relative;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  padding-top: 48px;
`

export const BlueIcon = styled.img`
  position: relative;
  width: 78px;
  height: 86px;

  margin: 0 auto;

  margin: 0 auto;
  margin-top: 24px;
`
