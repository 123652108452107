import {configureStore} from '@reduxjs/toolkit';

import {localStorageMiddleware} from './middleware';
import commonReducer from '../reducers/common';
import userReducer from '../reducers/user';
import itemListReducer from '../reducers/item-list';
import itemReducer from '../reducers/item';

export function makeStore(preloadedState) {
    return configureStore({
        reducer: {
            common: commonReducer,
            user: userReducer,
            itemList: itemListReducer,
            item: itemReducer,
        },
        devTools: true,
        preloadedState,
        middleware: (getDefaultMiddleware) => [
            ...getDefaultMiddleware(),
            localStorageMiddleware,
        ],
    });
}

const store = makeStore();

export default store;
