import styled from 'styled-components'

export const FormContainer = styled.form`
  position: relative;
`

export const FieldContainer = styled.div`
  position: relative;
  width: 406px;
`

export const FieldLabel = styled.label`
  position: relative;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Poppins';
  color: #000000;
`

export const TextField = styled.input`
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  padding: 0 18px;
  background: #f3f3f3;
  border: solid 2px #ededed;
  box-sizing: border-box;
  margin: 12px 0;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  font-weight: 400;
  font-family: 'Poppins';

  font-size: 18px;

  transition: background-color 275ms ease;

  &:hover,
  &:focus {
    background: #f9f9f9;
  }
`
