import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FormContainer } from '../common/form-styles'

export const ContentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`
export const UploadFilesContentContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`
export const UploadFilesButtonContainer = styled.div`
  position: relative;
  width: 406px;

  margin: 0 auto;
`

export const FiguresImageContainer = styled.img`
  position: relative;
  width: 188px;
  height: 215px;
  margin-top: 120px;
  margin-bottom: 32px;
`

export const UploadFilesButtonMessage = styled.div`
  position: relative;
  font-size: 18px;
  color: #9b9b9b;
  line-height: 24px;
  margin-top: 18px;
`

export const FileListContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px 80px;
  box-sizing: border-box;
`
