import {createAsyncThunk} from "@reduxjs/toolkit";
import {ItemAgent} from "../../api/itemAgent";

export const getRecentItems = createAsyncThunk(
    'item-list/get-recent-items',
    async (undefined, thunkApi) => {
        try {
            const items = await ItemAgent.getRecentItems();

            return {items}
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);

export const getAllItems = createAsyncThunk(
    'item-list/get-all-items',
    async (undefined, thunkApi) => {
        try {
            const items = await ItemAgent.getAllItems();

            return {items}
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);