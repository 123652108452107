import { createSlice } from '@reduxjs/toolkit'
import { Status } from '../utils/utils'
import { signIn } from '../components/account/sign-in/slice'
import { signUp, resendConfirmationToken, createAccount, confirmSignUpEmail } from '../components/account/sign-up/slice'
import { initApp } from '../components/app/slice'

const initialState = {
  user: null,
  signUpStep: 1,
  status: Status.IDLE,
  error: null,
}

function success(state) {
  state.status = Status.SUCCESS
  delete state.error
}

function failed(state, action) {
  state.status = Status.FAILURE
  state.error = {
    extra: action.payload.response.data.extra,
    message: action.payload.response.data.message,
    internalCode: action.payload.response.data.internalCode,
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers(builder) {
    builder.addCase(initApp.fulfilled, (state, action) => {
      state.user = action.payload.user
    })

    builder.addCase(signUp.fulfilled, (state) => {
      success(state)
      state.signUpStep = 2
    })
    builder.addCase(signUp.rejected, failed)

    builder.addCase(confirmSignUpEmail.fulfilled, (state) => {
      success(state)
      state.signUpStep = 3
    })
    builder.addCase(confirmSignUpEmail.rejected, failed)

    builder.addCase(createAccount.fulfilled, (state, action) => {
      success(state)
      state.signUpStep = 4
    })
    builder.addCase(createAccount.rejected, failed)

    builder.addCase(signIn.fulfilled, (state, action) => {
      success(state)
      state.user = action.payload.user
    })
    builder.addCase(signIn.rejected, failed)

    builder.addCase(resendConfirmationToken.rejected, failed)
    builder.addCase(resendConfirmationToken.fulfilled, success)

    builder.addMatcher(
      (action) => /user\/.*\/pending/.test(action.type),
      (state) => {
        state.status = Status.LOADING
      },
    )
  },
})

const selectUserSlice = (state) => state.user
export const selectUser = (state) => selectUserSlice(state).user
export const selectSignUpStep = (state) => selectUserSlice(state).signUpStep
export const selectIsLoading = (state) => selectUserSlice(state).status === Status.LOADING
export const selectError = (state) => selectUserSlice(state).error

const { reducer } = userSlice

export default reducer
