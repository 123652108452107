import * as THREE from 'three'
import { OBJLoader } from 'three/addons/loaders/OBJLoader'
import { MTLLoader } from 'three/addons/loaders/MTLLoader'
import { OrbitControls } from 'three/addons/controls/OrbitControls'
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js'

export default class WebGLView {
  windowHalfX = window.innerWidth / 2
  windowHalfY = window.innerHeight / 2

  step = 1

  object = null
  objectMaterials = []

  camera = null
  scene = null
  renderer = null
  intersection = null
  meshes = []

  boxWidth = 0
  boxHeight = 0

  loadedCallback = null
  updateLoadingProgressCallback = null

  markers = []

  pointer = null

  backgroundColor = '#F2F2F2'
  commentsMode = false

  constructor(loadedCallback, updateLoadingProgressCallback, boxWidth, boxHeight) {
    this.boxWidth = boxWidth
    this.boxHeight = boxHeight

    this.windowHalfX = this.boxWidth / 2
    this.windowHalfY = this.boxHeight / 2

    this.pointer = new THREE.Vector2()

    this.loadedCallback = loadedCallback
    this.updateLoadingProgressCallback = updateLoadingProgressCallback

    this.init()
    this.animate()
  }

  init() {
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(this.boxWidth, this.boxHeight)

    // tone mapping
    this.renderer.toneMapping = THREE.NoToneMapping

    this.renderer.outputEncoding = THREE.sRGBEncoding

    this.camera = new THREE.PerspectiveCamera(45, this.boxWidth / this.boxHeight, 1, 10000)
    this.camera.position.z = 250

    // controls
    const controls = new OrbitControls(this.camera, this.renderer.domElement)
    controls.addEventListener('change', this.render)
    controls.minDistance = 100
    controls.maxDistance = 250
    controls.enablePan = false

    // scene

    this.scene = new THREE.Scene()
    this.setBackgroundColor()

    // light
    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4)
    this.camera.add(ambientLight)

    const pointLight = new THREE.PointLight(0xffffff, 0.8)
    this.camera.add(pointLight)

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.3)
    directionalLight.position.set(10, 10, 10)
    this.camera.add(directionalLight)

    this.scene.add(this.camera)

    //

    this.raycaster = new THREE.Raycaster()
    this.raycaster.params.Points.threshold = 0.1

    // Load model script
    const loadModel = () => {
      if (this.object) {
        this.object.traverse((child) => {
          if (child.isMesh) {
            this.meshes.push(child)
          }
        })

        this.object.position.y = -125
        this.object.scale.x = 0.1
        this.object.scale.y = 0.1
        this.object.scale.z = 0.1

        this.scene.add(this.object)
      }
    }

    const manager = new THREE.LoadingManager(loadModel)

    const onProgress = (xhr) => {
      if (xhr.lengthComputable) {
        const percentComplete = (xhr.loaded / xhr.total) * 100
        this.updateLoadingProgressCallback(Math.round(percentComplete, 2))
      }
    }

    new MTLLoader().load('/models/Jacket-obj.mtl', (materials) => {
      materials.preload()

      this.objectMaterials = materials.materials

      new OBJLoader(manager).setMaterials(materials).load(
        '/models/Jacket-obj.obj',
        (obj) => {
          this.object = obj
          if (this.loadedCallback) {
            this.loadedCallback()
          }
        },
        onProgress,
        onError,
      )
    })

    const onError = () => {}
  }

  onWindowResize = () => {
    this.windowHalfX = this.boxWidth / 2
    this.windowHalfY = this.boxHeight / 2

    this.camera.aspect = this.boxWidth / this.boxHeight
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(this.boxWidth, this.boxHeight)
  }

  onPointerMove = (event) => {
    this.pointer.x = (event.offsetX / this.boxWidth) * 2 - 1
    this.pointer.y = -(event.offsetY / this.boxHeight) * 2 + 1
  }

  animate = () => {
    requestAnimationFrame(this.animate)
    this.render()
  }

  createMarker = () => {
    const sphereGeometry = new THREE.SphereGeometry(1, 32, 32)
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 })
    const newMarker = new THREE.Mesh(sphereGeometry, sphereMaterial)

    this.markers.push(newMarker)
    this.scene.add(newMarker)

    return newMarker
  }

  putMarketToTheObject = () => {
    if (this.commentsMode) {
      this.raycaster.setFromCamera(this.pointer, this.camera)

      const intersections = this.raycaster.intersectObjects(this.meshes, false)
      this.intersection = intersections.length > 0 ? intersections[0] : null

      if (this.intersection !== null) {
        const newMarker = this.createMarker()

        newMarker.position.copy(this.intersection.point)
      }
    }
  }

  // Equirectanglar background
  setBackgroundImage = (imageName) => {
    new RGBELoader().load(`/textures/venice_sunset_1k.hdr`, (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping

      this.scene.background = texture
      this.scene.environment = texture

      this.render()
    })
  }

  setBackgroundColor = (color) => {
    const threeJsBackgroundColor = new THREE.Color(color ? color : this.backgroundColor)
    this.scene.background = new THREE.Color(threeJsBackgroundColor.getHex())

    this.render()
  }

  setCommentsMode = (enabled) => {
    this.commentsMode = enabled

    if (enabled) {
      this.markers.forEach((marker) => {
        this.scene.add(marker)
      })
    } else {
      this.markers.forEach((marker) => {
        this.scene.remove(marker)
      })
    }
  }

  render = () => {
    this.renderer.render(this.scene, this.camera)
  }
}
