import {createAsyncThunk} from "@reduxjs/toolkit";
import {ItemAgent} from "../../api/itemAgent";

export const getItem = createAsyncThunk(
    'item/get-item',
    async ({uuid}, thunkApi) => {
        try {
            const item = await ItemAgent.getItem(uuid);

            return {item}
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    }
);