import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Icon } from '../../common/styles'

export const HeaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 74px;
`

export const HeaderLogo = styled(Link)`
  position: relative;
  float: left;
  margin-left: 36px;
  margin-top: 12px;
`

export const HeaderLogoImage = styled.img`
  position: relative;
  width: 159px;
  height: 50px;
`

export const HeaderVerticalDivider = styled.div`
  position: relative;
  width: 1px;
  height: 38px;
  background: #e7e7e7;
  float: left;
  margin: 0 16px;
  margin-top: 18px;
`
export const HeaderAllFilesLinkContainer = styled.div`
  position: relative;
  float: left;
  margin-top: 26px;
  height: 22px;
`
export const HeaderAllFilesLink = styled(Link)`
  position: relative;
  font-size: 18px;
  color: #3b3b3b;
`
export const HeaderButtonIcon = styled(Icon)`
  margin-left: 8px;
  margin-top: -2px;
`
export const HeaderButtonIconLeft = styled(HeaderButtonIcon)`
  margin-left: 0;
  margin-right: 8px;
`

export const HeaderSavedTime = styled.div`
  position: relative;
  float: left;
  font-size: 18px;
  font-weight: 500;
  color: #9a9a9a;
  margin-top: 26px;
  margin-left: 80px;

  transition: color 275ms ease;

  &:hover {
    cursor: pointer;
    color: #cecece;
  }
`

export const HeaderLeftSide = styled.div`
  position: relative;
  float: left;
`
export const HeaderRightSide = styled.div`
  position: relative;
  float: right;
  margin-right: 24px;
`
export const HeaderRightLink = styled(Link)`
  position: relative;
  display: block;
  width: 42px;
  height: 42px;
  float: right;

  text-align: center;

  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  border-radius: 21px;

  background: #f4f4f4;
  transition: background 275ms ease;

  margin-right: 16px;
  margin-top: 16px;

  &:hover {
    cursor: pointer;
    background: #fdfdfd;
  }
`
export const HeaderRightLinkWithText = styled.button`
  position: relative;
  display: block;
  height: 42px;
  padding: 0 16px;
  float: right;

  color: #2352cc;

  border: none;

  font-size: 16px;
  font-weight: 500;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  background: #e9eefa;
  transition: background 275ms ease;

  margin-right: 16px;
  margin-top: 16px;

  &:hover {
    cursor: pointer;
    background: #f8faff;
  }
`

export const HeaderRightIcon = styled(Icon)`
  margin-top: 8px;
`
