import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import {
  ItemPreviewContainer,
  ItemPreviewImage,
  ItemPreviewImageContainer,
  ItemPreviewInfoContainer,
  ItemPreviewInfoLeftContainer,
  ItemPreviewLastViewed,
  ItemPreviewName,
  MoreIconContainer,
} from './styles'
import { Clear } from '../common/styles'
import MoreIcon from '../../assets/images/more.svg'

function ItemPreview({ item }) {
  return (
    <ItemPreviewContainer>
      <Link to={`/item/${item.uuid}`}>
        <ItemPreviewImageContainer>
          <ItemPreviewImage src={item.image} alt={item.name} />
        </ItemPreviewImageContainer>
        <ItemPreviewInfoContainer>
          <ItemPreviewInfoLeftContainer>
            <ItemPreviewName>{item.name}</ItemPreviewName>
            <ItemPreviewLastViewed>Last viewed {item.lastViewedTime} ago</ItemPreviewLastViewed>
          </ItemPreviewInfoLeftContainer>
          <MoreIconContainer src={MoreIcon} alt={'More details'} />
          <Clear />
        </ItemPreviewInfoContainer>
      </Link>
    </ItemPreviewContainer>
  )
}

export default memo(ItemPreview)
