import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const signIn = createAsyncThunk('user/account/sign-in', async ({ email, password }, thunkApi) => {
  try {
    return await UserAgent.signIn(email, password)
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})
