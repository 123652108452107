import styled from 'styled-components'
import { Icon } from '../common/styles'

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
`
export const ItemsContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`
export const ItemPreviewImageContainer = styled.div`
  position: relative;
  width: 300px;
  height: 180px;

  text-align: center;

  background: #ffffff;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  -webkit-box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 8px 0px rgba(219, 219, 219, 1);

  transition: box-shadow 275ms ease;

  &:hover {
    cursor: pointer;

    -webkit-box-shadow: 0px 0px 8px 0px rgba(235, 235, 235, 1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(235, 235, 235, 1);
    box-shadow: 0px 0px 8px 0px rgba(235, 235, 235, 1);
  }
`

export const ItemPreviewImage = styled.img`
  position: relative;
  height: 156px;
  max-width: 276px;
  margin-top: 12px;
`
export const ItemPreviewInfoContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 12px;
`
export const ItemPreviewInfoLeftContainer = styled.div`
  position: relative;
  width: 276px;
  float: left;
`
export const ItemPreviewContainer = styled.div`
  position: relative;
  width: 300px;
  margin: 12px;
`
export const ItemPreviewName = styled.div`
  position: relative;
  font-size: 18px;
  margin-bottom: 8px;
  color: #3b3b3b;
`
export const ItemPreviewLastViewed = styled.div`
  position: relative;
  font-size: 10px;
  color: #9a9a9a;
`
export const MoreIconContainer = styled(Icon)`
float: right
width: 24px;
height: 24px;
transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`
