import { requests } from './agent'
import WearImage1 from '../assets/images/wear_1.png'
import WearImage2 from '../assets/images/wear_2.png'
import WearImage3 from '../assets/images/wear_3.png'

export const ItemAgent = {
  getAllItems: () => {
    // return requests.post('/1_0/user/account/sign-in', { email, password })
    return [
      {
        uuid: 'test',
        name: 'TEST',
        lastViewedTime: '123',
        image: '123',
      },
      {
        uuid: 'test1',
        name: 'TEST1',
        lastViewedTime: '111',
        image: '1111',
      },
      {
        uuid: 'test2',
        name: 'TEST2',
        lastViewedTime: '3333',
        image: '124443',
      },
    ]
  },
  getItem: (uuid) => {
    return {
      uuid: uuid,
      name: 'TEST',
      lastViewedTime: '123',
      image: '123',
    }
  },
  getRecentItems: () => {
    // return requests.post('/1_0/user/account/sign-up', { email })
    // return {
    //   apiToken: '123'
    // }

    return [
      {
        uuid: '36b83406-4342-4562-9d38-347cc06767fa',
        name: 'Spring collection #342',
        lastViewedTime: '12:00 pm 23/04/2022',
        image: WearImage1,
      },
      {
        uuid: '4d678942-1af2-4fb0-98dd-0e96805723fb',
        name: 'Spring collection #318',
        lastViewedTime: '12:00 pm 23/04/2022',
        image: WearImage2,
      },
      {
        uuid: 'a5a44af2-5db9-4c7d-b65a-b7e875b272c0',
        name: 'Spring collection #345',
        lastViewedTime: '12:00 pm 23/04/2022',
        image: WearImage3,
      },
    ]
  },
}
