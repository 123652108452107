import React, {memo, useEffect} from "react";
import {Link} from "react-router-dom";
import {selectUser} from "../../reducers/user";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";

function Home() {
    const navigate = useNavigate()
    const user = useSelector(selectUser)

    useEffect(() => {
        if (user) {
            navigate(`/dashboard`)
        }
    }, [])

    return (<div>
        <Link to={"/sign-in"}>Sign in</Link>
        <Link to={"/sign-up"}>Sign up</Link>
    </div>)
}

export default memo(Home)