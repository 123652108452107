import agent from '../api/agent'
import { signIn } from '../components/account/sign-in/slice'
import { selectPlan} from '../components/account/sign-up/slice'

const localStorageMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case selectPlan.fulfilled.type:
    case signIn.fulfilled.type:
      window.localStorage.setItem('api-token', action.payload.apiToken)
      agent.setApiToken(action.payload.apiToken)
      break

    // case logout.type:
    //     window.localStorage.removeItem('api-token');
    //     agent.setApiToken(undefined);
    //     break;
  }

  return next(action)
}

export { localStorageMiddleware }
