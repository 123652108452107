import { createAsyncThunk } from '@reduxjs/toolkit'
import { UserAgent } from '../../../api/userAgent'

export const signUp = createAsyncThunk('user/account/sign-up', async ({ email }, thunkApi) => {
  try {
    return await UserAgent.signUp(email)
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})

export const confirmSignUpEmail = createAsyncThunk(
  'user/account/sign-up/confirm-email',
  async ({ token }, thunkApi) => {
    try {
      return await UserAgent.confirmSignUpEmail(token)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const createAccount = createAsyncThunk(
  'user/account/sign-up/create-account',
  async ({ name, password }, thunkApi) => {
    try {
      return await UserAgent.resendConfirmationToken(name, password)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)

export const selectPlan = createAsyncThunk('user/account/sign-up/select-plan', async ({ plan }, thunkApi) => {
  try {
    return await UserAgent.selectPlan(plan)
  } catch (error) {
    return thunkApi.rejectWithValue(error)
  }
})

export const resendConfirmationToken = createAsyncThunk(
  'user/account/sign-up/resend-token',
  async ({ email }, thunkApi) => {
    try {
      await UserAgent.resendConfirmationToken(email)
    } catch (error) {
      return thunkApi.rejectWithValue(error)
    }
  },
)
