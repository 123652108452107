import { createSlice } from '@reduxjs/toolkit'
import { Status } from '../utils/utils'
import { getRecentItems, getAllItems } from '../components/item-list/slice'

const initialState = {
  items: [],
  error: null
}

function failed(state, action) {
  if (action.meta.aborted) {
    state.status = Status.SUCCESS
  } else {
    state.status = Status.FAILURE
    state.error = {
      extra: action.payload.response.data.extra,
      message: action.payload.response.data.message,
      internalCode: action.payload.response.data.internalCode,
    }
  }
}

const itemListSlice = createSlice({
  name: 'itemList',
  initialState,
  reducers: {
    itemsUnloaded: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getRecentItems.fulfilled, (state, action) => {
      state.items = action.payload.items
    });
    builder.addCase(getRecentItems.rejected, failed)

    builder.addCase(getAllItems.fulfilled, (state, action) => {
      state.items = action.payload.items
    });
    builder.addCase(getAllItems.rejected, failed)
  },
})

const selectItemListSlice = (state) => state.itemList
export const selectItems = (state) => selectItemListSlice(state).items

const { reducer, actions } = itemListSlice

export const { itemsUnloaded } = actions

export default reducer
