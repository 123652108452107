import styled from 'styled-components'

export const ContentContainer = styled.div`
  position: absolute;
  left: 0;
  top: 74px;
  bottom: 0;
  width: 100%;
  background: #f6f4f1;
`
