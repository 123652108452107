import React, { memo } from 'react'
import Header from '../header/Header'
import { ContentContainer } from './styles'

const PrivateArea = ({ children }) => {
  return (
    <div>
      <Header />
      <ContentContainer>{children}</ContentContainer>
    </div>
  )
}

export default memo(PrivateArea)
