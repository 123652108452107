import { requests } from './agent'

export const UserAgent = {
  signIn: (email, password) => {
    // return requests.post('/1_0/user/account/sign-in', { email, password })
    return {
      apiToken: '123',
      user: {
        uuid: '990a7014-ac7b-43ba-872b-3b8ac209b559',
        name: 'Andrew Lekh',
      },
    }
  },
  signUp: (email) => {
    // return requests.post('/1_0/user/account/sign-up', { email })
    // return {
    //   apiToken: '123'
    // }
  },
  createAccount: (name, password) => {
    // return requests.post('/1_0/user/account/sign-up/create-account', { name, password })
    return {
      apiToken: '123',
    }
  },
  resendConfirmationToken: (email) => {
    // return requests.post('/1_0/user/account/sign-up/resend-token', { email })
  },
  selectPlan: (plan) => {
    // return requests.post('/1_0/user/account/sign-up/select-plan', { plan })
    return {
      apiToken: '123',
      user: {
        uuid: '990a7014-ac7b-43ba-872b-3b8ac209b559',
        name: 'Andrew Lekh',
      },
    }
  },
  confirmSignUpEmail: (token) => {
    // return requests.post('/1_0/user/account/sign-up/confirm-email', { token })
  },
  signOut: () => {
    // return requests.post('/sign-out')
  },
}
