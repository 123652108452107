import { createSlice } from '@reduxjs/toolkit'
import { initApp } from '../components/app/slice'
import { Status } from '../utils/utils'
import { signIn } from '../components/account/sign-in/slice'
import { selectPlan } from '../components/account/sign-up/slice'

const initialState = {
  appName: 'Chameo',
  appLoaded: false,
  status: Status.IDLE,
  redirectTo: undefined,
  errors: [],
}

function success(state) {
  state.appLoaded = true
  state.status = Status.SUCCESS
  delete state.errors
}

function failed(state, action) {
  state.appLoaded = true
  state.status = Status.FAILURE
  // state.errors = action.payload.errors;
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    clearRedirect(state) {
      delete state.redirectTo
    },
  },
  extraReducers(builder) {
    builder.addCase(initApp.fulfilled, success)

    builder.addCase(initApp.rejected, failed)

    builder.addCase(signIn.fulfilled, (state, action) => {
      state.redirectTo = '/dashboard'
    })

    builder.addCase(selectPlan.fulfilled, (state, action) => {
      state.redirectTo = '/dashboard'
    })

    builder.addMatcher(
      (action) => /common\/.*\/pending/.test(action.type),
      (state) => {
        state.status = Status.LOADING
      },
    )
  },
})

const { reducer, actions } = commonSlice

export const { clearRedirect } = actions

export default reducer
