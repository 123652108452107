import React, { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { initApp } from './slice'
import agent from '../../api/agent'
import SignIn from '../account/sign-in/SignIn'
import SignUp from '../account/sign-up/SignUp'
import { memo } from 'react'
import Home from '../home/Home'
import { clearRedirect } from '../../reducers/common'
import { useNavigate } from 'react-router'
import Dashboard from '../dashboard/Dashboard'
import ProtectedRoute from '../common/ProtectedRoute'
import ItemViewer from '../item-viewer/ItemViewer'

function App() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const redirectTo = useSelector((state) => state.common.redirectTo)
  const appLoaded = useSelector((state) => state.common.appLoaded)

  useEffect(() => {
    if (redirectTo) {
      navigate('/dashboard')
      dispatch(clearRedirect())
    }
  }, [redirectTo])

  useEffect(() => {
    const token = window.localStorage.getItem('api-token')
    agent.setApiToken(token)
    dispatch(initApp())
  }, [])

  if (appLoaded) {
    return (
      <>
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route exact path="/sign-up" element={<SignUp />} />
            <Route
              exact
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route exact path="/item/all" element={<SignUp />} />
            <Route
              exact
              path="/item/:uuid"
              element={
                <ProtectedRoute>
                  <ItemViewer />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Suspense>
      </>
    )
  } else {
    return (
      <>
        <p>Loading...</p>
      </>
    )
  }
}

export default memo(App)
