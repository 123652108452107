import React, { memo } from 'react'

function ErrorMessage({ error }) {
  if (!error || Object.keys(error).length === 0) {
    return null
  }

  // Todo error message process

  return <div>{error.message}</div>
}

export default memo(ErrorMessage)
