import React, { memo } from 'react'
import ItemPreview from './ItemPreview'
import { useSelector } from 'react-redux'
import { selectItems } from '../../reducers/item-list'
import { ContentContainer, ItemsContainer } from './styles'

function ItemList() {
  const items = useSelector(selectItems)

  if (!items) {
    return <div>Loading...</div>
  }

  if (items.length === 0) {
    return <div>No items are here... yet.</div>
  }

  return (
    <ContentContainer>
      <ItemsContainer>
        {items.map((item) => (
          <ItemPreview item={item} key={item.uuid} />
        ))}
      </ItemsContainer>
    </ContentContainer>
  )
}

export default memo(ItemList)
