// import {requests} from "./agent";

export const AppAgent = {
  init: () => {
    // return requests.post('/1_0/init')
    // return {status: 'ok'}

    return {
      apiToken: '123',
      user: {
        uuid: '990a7014-ac7b-43ba-872b-3b8ac209b559',
        name: 'Andrew Lekh',
      },
    }
  },
}
