import React, { memo, useCallback, useEffect, useState } from 'react'
import * as THREE from 'three'
import { itemUnloaded, selectItem } from '../../reducers/item'
import { getItem } from './slice'
import { useParams } from 'react-router'
import WebGLView from './web-gl/WebGLView'
import { useDispatch, useSelector } from 'react-redux'
import { BlockPicker } from 'react-color'
import {
  BackgroundColor,
  BackgroundImage,
  BackgroundsContainer,
  BackgroundsItemsContainer,
  ColorBox,
  ColorPickerContainer,
  CommentsModeMessage,
  ControlPanelButton,
  ControlPanelButtonImage,
  ControlPanelContainer,
  EmptyIcon,
  EmptyIconContainer,
  HorizontalDivider,
  LoadingMessage,
  LoadingMessageContainer,
  LoadingPercentage,
  MainContentContainer,
  MaterialName,
  MaterialsContainer,
  MaterialsListContainer,
  MaterialsListItemContainer,
  SpinnerContainer,
  UploadBackgroundButton,
  WorkAreaContainer,
} from './styles'
import ColorIconImage from '../../assets/images/colors-icon.svg'
import BackgroundIconImage from '../../assets/images/background-icon.svg'
import CommentsIconImage from '../../assets/images/comments-icon.svg'
import EmptyIconImage from '../../assets/images/empty-icon.svg'
import Background1Image from '../../assets/images/bg-1.jpg'
import Background2Image from '../../assets/images/bg-2.jpg'
import Background3Image from '../../assets/images/bg-3.jpg'
import Background4Image from '../../assets/images/bg-4.jpg'
import Background5Image from '../../assets/images/bg-5.jpg'
import Background6Image from '../../assets/images/bg-6.jpg'
import Background7Image from '../../assets/images/bg-7.jpg'
import UploadIconImage from '../../assets/images/upload-icon.svg'
import { Clear } from '../common/styles'
import { HeaderButtonIconLeft } from '../layouts/header/styles'
import { ThreeDots } from 'react-loader-spinner'

function ItemViewer({ match }) {
  const dispatch = useDispatch()
  const item = useSelector(selectItem)
  const { uuid } = useParams()
  const [modelLoadingProgress, setModelLoadingProgress] = useState(true)
  const [modelLoading, setModelLoading] = useState(true)
  const [materials, setMaterials] = useState(null)
  const [activeColorPickerUuid, setActiveColorPickerUuid] = useState(null)

  const POPUPS = {
    MATERIALS: 'materials',
    BACKGROUNDS: 'backgrounds',
    COMMENTS: 'comments',
  }
  const [webgl, setWebgl] = useState(null)
  const [showMaterials, setShowMaterials] = useState(false)
  const [showBackgrounds, setShowBackgrounds] = useState(false)
  const [showComments, setShowComments] = useState(false)

  const togglePopup = (popup) => {
    setShowMaterials(popup === POPUPS.MATERIALS ? !showMaterials : false)
    setShowBackgrounds(popup === POPUPS.BACKGROUNDS ? !showBackgrounds : false)
    setShowComments(popup === POPUPS.COMMENTS ? !showComments : false)

    if (popup === POPUPS.COMMENTS) {
      webgl.setCommentsMode(!showComments)
    }
  }

  useEffect(() => {
    const fetchItem = dispatch(getItem(uuid))
    return () => {
      dispatch(itemUnloaded())
      fetchItem.abort()
    }
  }, [match])

  const refCallback = useCallback((node) => {
    if (node !== null) {
      initWebGL(node)
    }
  }, [])

  const onTogglePicker = (event, uuid) => {
    event.preventDefault()
    setActiveColorPickerUuid(activeColorPickerUuid === null ? uuid : null)
  }

  const handleColorChange = (materialObjet, color) => {
    const threejsColor = new THREE.Color(color.hex)
    materialObjet.map = null
    materialObjet.color.setHex(threejsColor.getHex())
    materialObjet.needsUpdate = true
  }

  const setBackgroundColor = (color) => {
    webgl.setBackgroundColor(color)
  }

  const setBackgroundImage = () => {
    webgl.setBackgroundImage()
  }

  const initWebGL = (container) => {
    const webgl = new WebGLView(
      () => {
        console.log('Object is fully loaded')
        setMaterials(webgl.objectMaterials)
        setModelLoading(false)
      },
      (percentage) => {
        setModelLoadingProgress(percentage)
        console.log('model ' + Math.round(percentage, 2) + '% downloaded')
      },
      container.clientWidth,
      container.clientHeight,
    )

    setWebgl(webgl)
    container.appendChild(webgl.renderer.domElement)

    window.addEventListener('resize', webgl.onWindowResize)
    container.addEventListener('mousemove', webgl.onPointerMove)
    container.addEventListener('dblclick', webgl.putMarketToTheObject)
  }

  return (
    <>
      <MainContentContainer>
        {modelLoading && (
          <LoadingMessageContainer>
            <LoadingMessage>Your model is loading...</LoadingMessage>
            <SpinnerContainer>
              <ThreeDots
                height="48"
                width="48"
                radius="6"
                color="#2352cc"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
              />
              <LoadingPercentage>{modelLoadingProgress}%</LoadingPercentage>
            </SpinnerContainer>
          </LoadingMessageContainer>
        )}

        {!modelLoading && (
          <ControlPanelContainer>
            <ControlPanelButton onClick={() => togglePopup(POPUPS.MATERIALS)}>
              <ControlPanelButtonImage src={ColorIconImage} alt={'Color icon'} />
            </ControlPanelButton>
            <ControlPanelButton onClick={() => togglePopup(POPUPS.BACKGROUNDS)}>
              <ControlPanelButtonImage src={BackgroundIconImage} alt={'Background icon'} />
            </ControlPanelButton>
            <ControlPanelButton onClick={() => togglePopup(POPUPS.COMMENTS)}>
              <ControlPanelButtonImage src={CommentsIconImage} alt={'Comments icon'} />
            </ControlPanelButton>
          </ControlPanelContainer>
        )}

        <WorkAreaContainer ref={refCallback} />
        {showMaterials && materials !== null && (
          <MaterialsContainer>
            <MaterialsListContainer>
              {Object.keys(materials).map((key) => {
                const materialObject = materials[key]

                return (
                  <MaterialsListItemContainer key={materialObject.uuid}>
                    <MaterialName>{materialObject.name}</MaterialName>
                    <ColorBox
                      onClick={(event) => {
                        onTogglePicker(event, materialObject.uuid)
                      }}
                      color={materialObject.color.getHexString()}
                    />
                    {activeColorPickerUuid === materialObject.uuid && (
                      <ColorPickerContainer>
                        <BlockPicker
                          color={`#${materialObject.color.getHexString()}`}
                          onChangeComplete={(color) => {
                            handleColorChange(materialObject, color)
                          }}
                        />
                      </ColorPickerContainer>
                    )}
                  </MaterialsListItemContainer>
                )
              })}
            </MaterialsListContainer>
          </MaterialsContainer>
        )}
        {showBackgrounds && (
          <BackgroundsContainer>
            <BackgroundsItemsContainer>
              <BackgroundColor
                color={'#F2F2F2'}
                onClick={() => {
                  setBackgroundColor('#F2F2F2')
                }}
              />
              <BackgroundColor
                color={'#CEBCA6'}
                onClick={() => {
                  setBackgroundColor('#CEBCA6')
                }}
              />
              <BackgroundColor
                color={'#017DA4'}
                onClick={() => {
                  setBackgroundColor('#017DA4')
                }}
              />
              <BackgroundColor
                color={'#B0B135'}
                onClick={() => {
                  setBackgroundColor('#B0B135')
                }}
              />
              <BackgroundColor
                color={'#D38FC4'}
                onClick={() => {
                  setBackgroundColor('#D38FC4')
                }}
              />
              <BackgroundColor
                color={'#B8B8B8'}
                onClick={() => {
                  setBackgroundColor('#B8B8B8')
                }}
              />
              <BackgroundColor
                color={'#D0B68D'}
                onClick={() => {
                  setBackgroundColor('#D0B68D')
                }}
              />
              <BackgroundColor
                color={'#7E6D6D'}
                onClick={() => {
                  setBackgroundColor('#7E6D6D')
                }}
              />
              <Clear />
            </BackgroundsItemsContainer>
            <HorizontalDivider />
            <BackgroundsItemsContainer>
              <EmptyIconContainer
                onClick={() => {
                  setBackgroundColor('#F2F2F2')
                }}
              >
                <EmptyIcon src={EmptyIconImage} />
              </EmptyIconContainer>
              <BackgroundImage
                src={Background1Image}
                onClick={() => {
                  setBackgroundImage('pano-img.jpg')
                }}
              />
              <BackgroundImage
                src={Background2Image}
                onClick={() => {
                  setBackgroundImage('venice_sunset_1k.hdr')
                }}
              />
              <BackgroundImage
                src={Background3Image}
                onClick={() => {
                  setBackgroundImage()
                }}
              />
              <BackgroundImage
                src={Background4Image}
                onClick={() => {
                  setBackgroundImage()
                }}
              />
              <BackgroundImage
                src={Background5Image}
                onClick={() => {
                  setBackgroundImage()
                }}
              />
              <BackgroundImage
                src={Background6Image}
                onClick={() => {
                  setBackgroundImage()
                }}
              />
              <BackgroundImage
                src={Background7Image}
                onClick={() => {
                  setBackgroundImage()
                }}
              />
              <Clear />
            </BackgroundsItemsContainer>
            <UploadBackgroundButton>
              <HeaderButtonIconLeft src={UploadIconImage} alt={'Upload icon'} />
              Upload image
            </UploadBackgroundButton>
          </BackgroundsContainer>
        )}
        {showComments && <CommentsModeMessage>Comments mode enabled</CommentsModeMessage>}
      </MainContentContainer>
    </>
  )
}

export default memo(ItemViewer)
