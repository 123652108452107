import { createSlice } from '@reduxjs/toolkit'
import { Status } from '../utils/utils'
import {getItem} from "../components/item-viewer/slice";

const initialState = {
  item: null,
  error: null,
  status: null,
}

function failed(state, action) {
  if (action.meta.aborted) {
    state.status = Status.SUCCESS
  } else {
    state.status = Status.FAILURE
    state.error = {
      extra: action.payload.response.data.extra,
      message: action.payload.response.data.message,
      internalCode: action.payload.response.data.internalCode,
    }
  }
}

const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    itemUnloaded: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getItem.fulfilled, (state, action) => {
      state.item = action.payload.item
    });
    builder.addCase(getItem.rejected, failed)
  },
})

const selectItemListSlice = (state) => state.item
export const selectItem = (state) => selectItemListSlice(state).item

const { reducer, actions } = itemSlice

export const { itemUnloaded } = actions

export default reducer
