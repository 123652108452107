import styled from 'styled-components'
import { Icon } from '../common/styles'
import { HeaderRightLinkWithText } from '../layouts/header/styles'

export const WorkAreaContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: #f6f4f1;
`

export const MainContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const MaterialsContainer = styled.div`
  position: absolute;
  background: #ffffff;
  left: 80px;
  bottom: 120px;
  height: calc(100% - 136px);
  width: 320px;

  overflow: scroll;

  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
`

export const ControlPanelContainer = styled.div`
  position: absolute;
  bottom: 40px;
  left: 84px;

  height: 64px;

  background: #ffffff;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  z-index: 1;
`
export const ControlPanelButton = styled.button`
  position: relative;
  float: left;
  height: 64px;
  padding: 20px;
  box-sizing: border-box;

  background: #ffffff;
  border: none;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  transition: background 275ms ease;

  &:hover {
    cursor: pointer;

    background: #e9eefa;
  }
`
export const ControlPanelButtonImage = styled.img`
  position: relative;
  height: 24x;
  width: 24x;
`
export const MaterialsListContainer = styled.ul`
  position: relative;
  width: 100%;
  padding: 0;
  padding-right: 32px;
  box-sizing: border-box;
  margin: 0;
`
export const MaterialsListItemContainer = styled.li`
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  padding-left: 24px;
  box-sizing: border-box;
`
export const MaterialName = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 180px;
`
export const ColorBox = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  right: 12px;
  top: 12px;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

  background: #${(props) => props.color};

  transition: box-shadow 275ms ease;

  &:hover {
    cursor: pointer;

    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  }
`
export const ColorPickerContainer = styled.div`
  position: absolute;
  z-index: 2;

  right: 0;

  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
`
export const BackgroundsContainer = styled.div`
  position: absolute;
  z-index: 2;

  left: 80px;
  bottom: 120px;

  background: #ffffff;

  width: 232px;

  right: 0;

  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
`
export const BackgroundsItemsContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 12px;

  box-sizing: border-box;
`
export const BackgroundColor = styled.div`
  position: relative;
  width: 40px;
  height: 40px;

  float: left;
  margin: 6px;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  background: ${(props) => (props.color ? props.color : 'none')};

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

export const HorizontalDivider = styled.div`
  position: relative;
  background: #ebebeb;
  width: calc(100% - 24px);
  height: 1px;
  margin: 6px auto;
`

export const BackgroundImage = styled.img`
  position: relative;
  float: left;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  margin: 6px;

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

export const EmptyIconContainer = styled.div`
  position: relative;
  float: left;
  width: 40px;
  height: 40px;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  background: #f2f2f2;

  margin: 6px;

  text-align: center;

  transition: opacity 275ms ease;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

export const UploadBackgroundButton = styled(HeaderRightLinkWithText)`
  position: relative;
  display: block;
  float: none;
  width: calc(100% - 24px);

  margin: 12px auto;

  padding: 0;
`

export const EmptyIcon = styled(Icon)`
  margin-top: 8px;
`

export const CommentsModeMessage = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  padding: 0 24px;

  height: 42px;
  line-height: 42px;

  background: #ffffff;

  z-index: 2;

  font-weight: 500;
  font-family: 'Poppins';
  font-size: 18px;
  color: #333333;
`
export const LoadingMessageContainer = styled.div`
  position: absolute;
  text-align: center;
  z-index: 999;

  width: 400px;

  left: 50%;
  margin-left: -200px;

  padding-top: 120px;
`
export const LoadingMessage = styled.div`
  text-align: center;

  font-weight: 500;
  font-family: 'Poppins';
  font-size: 18px;
  color: #333333;
`
export const SpinnerContainer = styled.div`
  width: 48px;
  height: 48px;
  margin: 0 auto;
  margin-top: 32px;
`
export const LoadingPercentage = styled.div`
  text-align: center;
  width: 128px;
  height: 48px;
  margin: 0 auto;
  margin-top: 32px;
  font-size: 24px;
  color: #333333;
`
