import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectItem } from '../../../reducers/item'
import {
  HeaderButtonIcon,
  HeaderAllFilesLink,
  HeaderAllFilesLinkContainer,
  HeaderContainer,
  HeaderItemName,
  HeaderLeftSide,
  HeaderLogo,
  HeaderLogoImage,
  HeaderRightIcon,
  HeaderRightLink,
  HeaderRightLinkWithText,
  HeaderRightSide,
  HeaderSavedTime,
  HeaderVerticalDivider,
  HeaderButtonIconLeft,
} from './styles'
import LogoImage from '../../../assets/images/logo.svg'
import FolderIcon from '../../../assets/images/folder-icon.svg'
import NotificationIcon from '../../../assets/images/notification-icon.svg'
import UserIcon from '../../../assets/images/user-icon.svg'
import DropDownIcon from '../../../assets/images/drop-down.svg'
import ShareIcon from '../../../assets/images/users-icon.svg'
import EmbedIcon from '../../../assets/images/link-icon.svg'

function Header() {
  const currentItem = useSelector(selectItem)

  return (
    <HeaderContainer>
      <HeaderLeftSide>
        <HeaderLogo to={'/dashboard'}>
          <HeaderLogoImage src={LogoImage} alt="Chameo logo" />
        </HeaderLogo>
        <HeaderVerticalDivider />
        <HeaderAllFilesLinkContainer>
          <HeaderAllFilesLink to={'/dashboard'}>
            {!currentItem ? <>All files</> : <>Spring collection #342</>}
            <HeaderButtonIcon src={FolderIcon} alt={'Folder icon'} />
          </HeaderAllFilesLink>
        </HeaderAllFilesLinkContainer>
      </HeaderLeftSide>
      {currentItem && (
        <HeaderSavedTime>
          Last saved 02:22pm
          <HeaderButtonIcon src={DropDownIcon} alt={'More icon'} />
        </HeaderSavedTime>
      )}
      <HeaderRightSide>
        {currentItem && (
          <>
            <HeaderRightLinkWithText>
              <HeaderButtonIconLeft src={EmbedIcon} alt={'Embed icon'} />
              Embed
            </HeaderRightLinkWithText>
            <HeaderRightLinkWithText>
              <HeaderButtonIconLeft src={ShareIcon} alt={'Share icon'} />
              Share
            </HeaderRightLinkWithText>
          </>
        )}
        <HeaderRightLink to={'#'}>
          <HeaderRightIcon src={UserIcon} alt={'User'} />
        </HeaderRightLink>
        <HeaderRightLink to={'#'}>
          <HeaderRightIcon src={NotificationIcon} alt={'Notifications'} />
        </HeaderRightLink>
      </HeaderRightSide>
    </HeaderContainer>
  )
}

export default memo(Header)
